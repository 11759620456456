const reduce = (numerator, denominator) => {
  const gcd = (a, b): number => (b ? gcd(b, a % b) : a);
  const gcdRes = gcd(numerator, denominator);
  return [numerator / gcdRes, denominator / gcdRes];
};

interface Item {
  name: string;
  key: number;
}

// used for all products, except banners
export const permutateInchesDimensions = (min, max): Item[] => {
  const denominator = 8;
  const steps = (max - min) * denominator;
  const data = [];

  for (let i = 0; i <= steps; i++) {
    const inches = min + i / denominator;
    const wholeInches = Math.floor(inches);
    const extraDecimal = inches - wholeInches;
    const numerator = extraDecimal * denominator;

    let fraction = null;

    if (numerator === 0) {
      fraction = '';
    } else {
      const result = reduce(numerator, denominator);
      fraction = ` (${wholeInches} ${result[0]}/${result[1]}")`;
    }
    const name = `${inches}"${fraction}`;

    data.push({ name, key: inches.toString() });
  }

  return data;
};

// used for banners
export const permutateFeetDimensions = (min, max): Item[] => {
  const data = [];

  for (let i = min / 12; i <= max / 12; i++) {
    const name = `${i}' (${i * 12}")`;

    data.push({ name, key: (i * 12).toString() });
  }

  return data;
};
