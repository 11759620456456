import * as React from 'react';
import { Button } from 'styleguide/components';

interface Props {
  onClick: () => void;
}

const CustomQuoteWarningButton = ({ onClick }: Props) => (
  <Button type="button" color="orange" onClick={onClick}>
    Click here for custom quote info
  </Button>
);

export default CustomQuoteWarningButton;
