import * as React from 'react';
import { KeyVal } from 'libs/utils/common-types';
import { Product as ProductType } from 'styleguide/components/QuoteEditForm/Product';

export type QuoteStatus =
  | 'productLoading'
  | 'ready'
  | 'quoting'
  | 'quoteError'
  | 'customQuote'
  | 'addingToCart'
  | 'minRequirementsError';

export interface QuoteContextType {
  product: ProductType;
  alias: string;
  initialValues: KeyVal;
  initialPrice: number | null;
  errorMessage: string;
  price: number;
  cost: number;
  weight: number;
  status: QuoteStatus;
  discountPrice?: number;
  discountMessage: string;
  displayNotes: boolean;
  onChange: (prevValues: KeyVal, values: KeyVal, force?: boolean) => Promise<number>;
  onSubmit: (values: KeyVal) => void;
  onCustomize?: () => void;
  canCustomize?: boolean;
  showHiddenOptions: boolean;
  instantQuote: boolean;
}

const QuoteContext = React.createContext<QuoteContextType>(null);

export default QuoteContext;
