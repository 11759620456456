import { connect, useFormikContext } from 'formik';
import { useEffect } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { KeyVal } from 'libs/utils/common-types';

interface Props {
  onChange: (prevValues: KeyVal, values: KeyVal) => void;
}

const FormikEffect = ({ ...props }: Props) => {
  const { values } = useFormikContext<KeyVal>();
  const prevValues = usePrevious(values);

  useEffect(() => {
    props.onChange(prevValues, values);
  }, [values]);

  return null;
};

export default connect(FormikEffect);
