import * as React from 'react';
import { contactPhone } from 'app/constants';
import Link from 'styleguide/components/Links/Link';

import numberOfPages from 'assets/images/tooltips/number_of_pages.png';
import divider from 'assets/images/tooltips/divider.png';
import fullBleed from 'assets/images/tooltips/full_bleed.png';
import collated from 'assets/images/tooltips/collated.png';
import dividerTabs from 'assets/images/tooltips/dividerTabs.png';
import tabName from 'assets/images/tooltips/tabName.png';
import tabPage from 'assets/images/tooltips/tabPage.png';

export default {
  'saddle-quantity-tooltip': {
    title: 'How To Determine Number of Pages',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">How To Determine Number of Pages</h3>
        <div className="font-hvBold underline">Books and Booklets</div>
        <div>
          Count all pages in your finished product - including the front cover, inside front cover, back
          cover, and inside back cover.
        </div>
        <img width="182" height="91" src={numberOfPages} alt="number of pages" />
        <div> This book is 8 pages, including covers. </div>
        <ul className="list-inside leading-6">
          <strong className="underline">Still have questions?</strong>
          <li>Please contact us. We are here to help! </li>
          <li>{contactPhone}</li>
          <li>
            <Link to="/contact-us" color="blue" underline="always">
              Email Us
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  'collation-tooltip-partial': {
    title: 'Difference between collated and uncoated',
    content: <img src={collated} width="300" height="300" alt="collated vs uncoated" />,
  },
  'color-copies-quantity-tooltip': {
    title: 'How To Determine Number of Pages',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">How To Determine Number of Pages</h3>
        <div>
          If your PDF, Word doc, etc. is 24 pages, simply enter &quot;24.&quot; Don&apos;t overthink it!{' '}
        </div>
        <ul className="list-inside leading-6">
          <strong className="underline">Single-Sided</strong>
          <li>If you order 24 pages, you&apos;ll receive 24 sheets</li>
          <li>One side of the sheet is printed</li>
        </ul>
        <ul className="list-inside leading-6">
          <strong className="underline">Double-Sided</strong>
          <li>If you order 24 pages, you&apos;ll receive 12 sheets</li>
          <li>Both sides of the sheet are printed</li>
        </ul>
        <ul className="list-inside leading-6">
          <strong className="underline">Still have questions?</strong>
          <li>Contact us right now! It is always better to do it right the first time.</li>
          <li>{contactPhone}</li>
          <li>
            <Link to="/contact-us" color="blue" underline="always">
              Email Us
            </Link>
          </li>
        </ul>
      </div>
    ),
  },
  'divider-sheets-tooltip-partial': {
    title: 'Where are divider sheets placed?',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Divider Sheets</h3>
        <div className="font-hvBold underline">Where are divider sheets placed?</div>
        <ul className="list-inside leading-6">
          <li>A divider sheet is placed between each document/copy. </li>
          <li>
            The number of documents/copies is selected in the
            <span className="italic"> &quot;How many copies would you like?&quot;</span> section of the quoter
          </li>
          <li className="italic">
            Divider sheets will <span className="underline font-hvBold uppercase">not</span> be put inside a
            document/copy, they will only be put between documents/copies.
          </li>
        </ul>
        <img src={divider} width="300" height="305" alt="divider" />
      </div>
    ),
  },
  'full-bleed-tooltip-partial': {
    title: 'How to determine full bleed',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Full Bleed</h3>
        <div className="font-hvBold underline">How to determine full bleed</div>
        <div>If your design extends to any edge of the sheet, then you should select full bleed.</div>
        <img
          width="419"
          height="95"
          className="examples-of-full-bleed-tooltip"
          src={fullBleed}
          alt="How to determine full bleed"
        />
      </div>
    ),
  },
  'mailing-services-tooltip-partial': {
    title: 'Mailing Services',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Mailing Services</h3>
        <div>
          Our mailing service ensures you get the largest discount from the post office. We take the hassle
          out of preparing your mail for delivery.
        </div>
        <ul className="list-inside">
          <div className="font-hvBold underline">What does the Mailing Service Include?</div>
          <li>Address Verification</li>
          <li>Addressing and Sorting</li>
          <li>Paperwork and Delivery</li>
        </ul>
        <ul className="list-inside">
          <div className="font-hvBold underline">Still have a question?</div>
          <div>Please contact us. We&apos;re here to help!</div>
          <li>Call us at 1-877-649-5463</li>
          <li>
            Email us at{' '}
            <a href="mailto:postcardmailing@mgxcopy.com?Subject=Postcard%20Mailing%20and%20Printing">
              postcardmailing@mgxcopy.com
            </a>
          </li>
        </ul>
      </div>
    ),
  },
  'paper-type-tooltip-partial': {
    title: 'Paper Info',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Paper Info</h3>
        <ul className="list-inside">
          <div className="font-hvBold underline">Standard Uncoated</div>
          <li>50 lb. text ~ 20 lb. bond, or copy paper</li>
          <li>60 lb. text ~ 24 lb. bond</li>
          <li>70 lb. text ~ 28 lb. bond</li>
        </ul>
        <ul className="list-inside">
          <div className="font-hvBold underline">Gloss / Matte Coated Text</div>
          <li>Comparable in thickness to standard paper</li>
          <li>High-quality finish &amp; coating</li>
          <li>Used for magazines pages, brochures, flyers</li>
          <li>60lb Gloss gives an ultra-thin magazine feel</li>
        </ul>
        <ul className="list-inside">
          <div className="font-hvBold underline">Card Stock / Cover Stock</div>
          <li>
            <span className="italic text-black">80 lb. cover is substantially thicker than 80 lb. text</span>
          </li>
          <li>High-quality finish &amp; coating</li>
          <li>Often used for book covers, posters, thicker flyers</li>
        </ul>
        <ul className="list-inside">
          <div className="font-hvBold underline">Tear resistant</div>
          <li>
            <span className="italic text-black">Water resistant, Tear resistant. </span>
          </li>
          <li>Resistant to tearing and spills</li>
        </ul>
      </div>
    ),
  },
  'uv-tooltip-partial': {
    title: 'UV ',
    content: (
      <div className="space-y-2">
        UV coating - <span className="uv-tooltip quoter-tooltip text-orange-700">Recommended!</span>
      </div>
    ),
  },
  'tab-dividers-tooltip': {
    title: 'help',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Tab Dividers</h3>
        <div>
          Divider Tabs allow you to quickly navigate through your document. You can either choose printing
          extended part of the tab alone. Or, you can choose to extend your design to print on the full
          divider page.
        </div>
        <img width="527" height="288" src={dividerTabs} alt="divider Tabs" />
      </div>
    ),
  },
  'tab-name-tooltip': {
    title: 'help',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Tab Text</h3>
        <div>We&apos;ll add text to the tab extension for you - what do you want it to say?</div>
        <img width="484" height="511" src={tabName} alt="Tab Name" />
      </div>
    ),
  },
  'tab-page-tooltip': {
    title: 'tab page',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Tab Page Location</h3>
        <div>Tell us where the Divider Tab should be located within your digital file.</div>
        <img width="479" height="344" src={tabPage} alt="Tab Page" />
      </div>
    ),
  },
  'binder-inserts-tooltip': {
    title: 'binder inserts',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Binder Inserts</h3>
        <div>
          Our binders have clear plastic pockets on the outside that allow you to insert a sheet to create a
          custom exterior. We can provide these cover sheets for the pocket on the front cover, back cover,
          and spine of the binder.
        </div>
      </div>
    ),
  },
  'assembly-tooltip': {
    title: 'assembly',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Assembly</h3>
        <div>
          If we&apos;re printing covers for you, we can also insert them into the binder on your behalf,
          saving you the trouble. Or, if you&apos;d prefer, we can bundle them separately and ship them with
          your binders.
        </div>
      </div>
    ),
  },
  'binder-size-tooltip': {
    title: 'binder size',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Binder Width</h3>
        <div>
          Binder width is measured by the diameter of the metal ring within the binder. If you have a specific
          document that you need to fit within your binder, and aren&apos;t sure how to calculate the width,
          please contact our Customer Service team for assistance.
        </div>
      </div>
    ),
  },
  'step-stakes-tooltip': {
    title: 'step stakes',
    content: (
      <div className="space-y-2">
        <h3 className="text-base font-hvBold capitalize">Step Stakes</h3>
        <div>
          These are the stakes that will hold your sign up. The choice between Standard or Heavy Duty may
          depend on your budget, or how long you plan to have the signs standing - whether that&apos;s a few
          days or several months.
        </div>
      </div>
    ),
  },
};
