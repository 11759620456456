import * as React from 'react';
import AttributeGroup from './AttributeGroup/AttributeGroup';
import { AttributeGroup as AttributeGroupInterface } from 'api/persephone/productTypes';
import QuoterAddToCartButtonPrice from 'bundles/App/pages/ProductDetailsPage/AddToCart/QuoterAddToCartButtonPrice/QuoterAddToCartButtonPrice';
import { Form, useFormikContext } from 'formik';
import { KeyVal } from 'libs/utils/common-types';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';

interface Props {
  singleColumn?: boolean;
  hideTooltips?: boolean;
  showPriceAndCartButton?: boolean | false;
}

const QuoteEditForm = ({ singleColumn, hideTooltips, showPriceAndCartButton }: Props) => {
  const { values } = useFormikContext<KeyVal>();
  const quoteContext = React.useContext(QuoteContext);

  return (
    <>
      <input type="hidden" name="quoter_json" className="quoter-json" value={JSON.stringify(values)} />
      {quoteContext.status !== 'productLoading' && values && (
        <Form>
          {quoteContext.product
            .getProduct()
            .attributeGroups.map((elem: AttributeGroupInterface, i: number) => (
              <AttributeGroup
                singleColumn={singleColumn}
                hideTooltips={hideTooltips}
                product={quoteContext.product}
                isCustomDimensionSelected={values.dimension && values.dimension === 'custom'}
                isTabsSelected={values['tab-dividers'] && values['tab-dividers'] !== 'no-tab-dividers'}
                key={i}
                {...elem}
              />
            ))}
          {showPriceAndCartButton && <QuoterAddToCartButtonPrice showDiscountMessage />}
        </Form>
      )}
    </>
  );
};

export default QuoteEditForm;
