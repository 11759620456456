import * as React from 'react';
import cn from 'classnames';
import { H4 } from '../Heading';
import Span from '../Span/Span';
import { contactPhone } from 'app/constants';
import Link from '../Links/Link';

const Line = ({ title, children }) => (
  <div className="mx-0 my-2">
    <Span className="inline-block w-16 font-hvBold">{title}:</Span>
    {children}
  </div>
);

interface Props {
  noTitle?: boolean;
  noBg?: boolean;
}

const CustomQuoteWarning = ({ noBg, noTitle }: Props) => (
  <div className={cn('mb-5 p-5 text-left', noBg ? 'bg-none' : 'bg-[#fce2c1]')}>
    {noTitle ? null : <H4 className="mb-5 mt-0 !text-xl">Please, contact us for a custom quote:</H4>}
    <Line title="Phone">{contactPhone}</Line>
    <Line title="Email">
      <Link color="blue" underline="always" to="/contact-us">
        Send us an email
      </Link>
    </Line>
    <Span className="font-hvBold">Our service representatives will be pleased to assist you.</Span>
  </div>
);

export default CustomQuoteWarning;
