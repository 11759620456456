import * as React from 'react';
import Modal from 'styleguide/components/Modal/Modal';
import CustomQuoteWarning from './CustomQuoteWarning';

interface Props {
  onClose: () => void;
}

const CustomQuoteWarningModal: React.FC<Props> = ({ onClose }) => (
  <Modal onClose={onClose} shown>
    <CustomQuoteWarning noBg />
  </Modal>
);

export default CustomQuoteWarningModal;
