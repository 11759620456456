import { Value, ValueGroup } from 'api/persephone/productTypes';

type ControlType = 'input' | 'select' | 'select-optgroup' | 'custom-dimension-select' | 'radio-tabs';

export const getControlType = (values: Value[], valueGroups: ValueGroup[]): ControlType => {
  const isValueGroupsEmpty = !valueGroups.length;
  const isValuesEmpty = !values.length;

  if (isValueGroupsEmpty && isValuesEmpty) {
    return 'input';
  }

  if (isValueGroupsEmpty && values.length === 2) {
    return 'radio-tabs';
  }

  if (isValueGroupsEmpty) {
    return 'select';
  }

  return 'select-optgroup';
};
