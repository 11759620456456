/* eslint-disable no-nested-ternary */
import * as React from 'react';
import cn from 'classnames';
import css from './QuoterAddToCartButtonPrice.scss';
import Span from 'styleguide/components/Span/Span';
import { formatMoney } from 'utils/money';
import { Button } from 'styleguide/components';
import IfDesktop from 'styleguide/components/Conditional/IfDesktop';
import IfTabletOrMobile from 'styleguide/components/Conditional/IfTabletOrMobile';
import { ErrorMessage } from 'styleguide/components/forms';
import Loader from 'styleguide/components/Loader/Loader';
import CustomQuoteWarningButton from 'styleguide/components/CustomQuoteWarning/CustomQuoteWarningButton';
import CustomQuoteWarningModal from 'styleguide/components/CustomQuoteWarning/CustomQuoteWarningModal';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';
import { useFormikContext } from 'formik';
import { KeyVal } from 'libs/utils/common-types';
import OrderContext from 'contexts/OrderContext/OrderContext';
import UserContext from 'contexts/UserContextContainer/UserContext';
import { submitQuoteRequest } from 'api/persephone/persephone';
import { useLocation } from 'react-router-dom';
import merge from 'lodash-es/merge';
import { clickRefreshQuote } from 'api/gtm';

interface Props {
  showDiscountMessage: boolean;
}

const QuoterAddToCartButtonPrice = ({ showDiscountMessage }: Props) => {
  const { order } = React.useContext(OrderContext);
  const { currentUser } = React.useContext(UserContext);
  const [customQuoteModalOpened, setCustomQuoteModalOpened] = React.useState(false);
  const quoteContext = React.useContext(QuoteContext);
  const { values, isValid } = useFormikContext<KeyVal>();
  const location = useLocation();
  const { price, onSubmit, discountPrice, discountMessage } = quoteContext;
  const isLoading =
    quoteContext.status === 'addingToCart' ||
    quoteContext.status === 'quoting' ||
    (price === null && quoteContext.status !== 'quoteError' && quoteContext.instantQuote);

  return (
    <div className={css.addToCartButtonPrice}>
      <IfDesktop className={cn(css.priceContainerDesktop)}>
        {isLoading ? (
          <Loader />
        ) : discountPrice ? (
          <>
            <Span className={cn(css.priceInherit, css.priceText)}>Total</Span>
            <Span className={cn(css.priceInherit, css.priceValue, css.priceStrikethrough)}>
              {formatMoney(price)}
            </Span>
            <Span className={cn(css.priceInherit, css.priceValue, css.priceDiscounted)}>
              {formatMoney(discountPrice)}
            </Span>
          </>
        ) : (
          <>
            <Span className={cn(css.priceFixed, css.priceText)}>Total</Span>
            <Span className={cn(css.priceFixed, css.priceValue)}>{formatMoney(price)}</Span>
          </>
        )}
      </IfDesktop>
      <IfTabletOrMobile className={css.priceContainerTabletMobile}>
        {customQuoteModalOpened && (
          <CustomQuoteWarningModal onClose={() => setCustomQuoteModalOpened(false)} />
        )}
        {quoteContext.status === 'customQuote' ? (
          <CustomQuoteWarningButton onClick={() => setCustomQuoteModalOpened(true)} />
        ) : isLoading ? (
          <Loader />
        ) : discountPrice ? (
          <>
            <Span className={cn(css.priceMobile, css.priceText)}>Total</Span>
            <Span className={cn(css.priceMobile, css.priceStrikethrough)}>{formatMoney(price)}</Span>
            <Span className={cn(css.priceMobile, css.priceDiscounted)}>{formatMoney(discountPrice)}</Span>
          </>
        ) : (
          <>
            <Span className={cn(css.priceMobile, css.priceText)}>Total</Span>
            <Span className={css.priceMobile}>{formatMoney(price)}</Span>
          </>
        )}
      </IfTabletOrMobile>
      {showDiscountMessage && discountMessage ? (
        <HtmlContent className={css.info} content={discountMessage} />
      ) : (
        discountPrice && (
          <div className={css.info}>To receive the discounted price, add the promo code in your cart.</div>
        )
      )}

      <div className={css.buttonErrorWrapper}>
        {quoteContext.instantQuote || (!quoteContext.instantQuote && quoteContext.price !== null) ? (
          <Button
            className={css.button}
            disabled={
              price === null ||
              quoteContext.status === 'quoteError' ||
              quoteContext.status === 'minRequirementsError' ||
              isLoading ||
              quoteContext.status === 'customQuote'
            }
            onClick={() => {
              onSubmit(merge(values, { product: quoteContext.product.getProductKey() }));
            }}
            type="button"
            color="orange"
          >
            {isLoading ? <Loader /> : 'Add to cart'}
          </Button>
        ) : (
          <Button
            className={css.button}
            disabled={isLoading || !isValid}
            onClick={() => {
              quoteContext.onChange(quoteContext.initialValues, values, true).then(res => {
                submitQuoteRequest(
                  order.number,
                  currentUser ? currentUser.id : null,
                  quoteContext.product.getProductKey(),
                  values,
                  location.pathname,
                  res,
                ).then(() => {
                  clickRefreshQuote({
                    location: 'instant_quote',
                    ...values,
                    error: quoteContext.errorMessage,
                  });
                });
              });
            }}
            type="button"
            color="blue"
          >
            Refresh Quote
          </Button>
        )}
        {['quoteError', 'minRequirementsError'].includes(quoteContext.status) && (
          <ErrorMessage message={quoteContext.errorMessage} />
        )}
      </div>
    </div>
  );
};

export default QuoterAddToCartButtonPrice;
