import flatMap from 'lodash-es/flatMap';
import find from 'lodash-es/find';
import flatten from 'lodash-es/flatten';

export const getValueTitleByAttributeKeyAndValue = (key, value, catalog) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const productKey of Object.keys(catalog)) {
    if (Object.prototype.hasOwnProperty.call(catalog, productKey)) {
      const attributeGroups = catalog[productKey].attributeGroups;
      const attributes = attributeGroups.flatMap(group => group.attributes);
      const attribute = attributes.find(o => o.key === key);

      if (attribute) {
        const options = flatten([flatMap(attribute.valueGroups, 'values'), attribute.values]);
        const option = find(options, o => o.key === value);

        if (option) {
          // @ts-ignore
          return option.name;
        }

        return value;
      }
    }
  }

  throw new Error(`Can't find attribute's name with key "${key}"`);
};
